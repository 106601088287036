

.app {
}

.default-chart {
  position: relative;
  height: 700px;
  width: 100%;
}

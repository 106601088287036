.root {
  position: relative;
  height: 48px;
  width: 48px;
  overflow: hidden;
}

.image {
  height: 48px;
  width: 48px;
  border-radius: 6px;
}

.button {
  position: absolute !important;
  opacity: 0;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.root:hover > .button {
  opacity: 1;
}

.icon {
  opacity: 1;
  color: black !important;
}

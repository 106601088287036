.panel {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  align-items: stretch;
}

.presets {
  overflow: scroll;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--divider);
  margin-right: 8px;
  margin-bottom: 8px;
}

.calendarRoot {
  margin: unset !important;
  margin-top: -16px !important;
  margin-left: -16px !important;
}

@media screen and (max-width: 560px) {
  .panel {
    flex-direction: column;
    align-items: center;
  }

  .presets {
    width: 100%;
    flex-direction: row;
    border-right: unset;
    margin-right: unset;
    }
}

.day {
  width: 40px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
}

.lonelyHovered {
  background-color: rgba(211, 211, 211, 0.346);
  border-radius: 40px;
}

.hovering {
  background-color: rgba(211, 211, 211, 0.246);
}

.between {
  background-color: rgba(211, 211, 211, 0.346);
}

.start {
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
}

.end {
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}

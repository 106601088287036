.root {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--page-padding);
}

.title {
  margin-bottom: 32px;
  font-size: 4em;
  text-align: center;
}

.welcome {
  margin-bottom: 16px;
  text-align: center;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  background-color: #1BD760;
  color: white;
  height: 40px;
  border-radius: 20px;
  padding: 0px 24px;
  font-weight: bold;
}

.root {
  padding: 16px;
  box-shadow: var(--shadow) !important;
  background-color: var(--background) !important;
}

.root > div > h3 {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.root > div > h3::before {
  content: '';
  position: relative;
  display: inline-block;
  width: 16px;
  height: 32px;
  background-color: var(--text-on-light);
  margin-right: 16px;
  border-radius: 6px;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.content {
  position: relative;
  height: 92%;
  padding-bottom: 16px;
}

.root {
  padding: 8px;
  text-align: center;
  border-radius: var(--radius) !important;
}

.root > strong {
  display: block;
  margin-top: 8px;
  margin-bottom: 8px;
}

.guest {
  width: 34px;
}

.item {
  border-radius: var(--radius) !important;
}

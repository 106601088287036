.alert {
  color: orange;
  font-weight: bold;
  margin: 16px 0px;
  display: block;
}

.noalert {
  color: green;
  font-weight: bold;
  margin: 16px 0px;
  display: block;
}

.progress {
  margin-bottom: 8px;
}

.selectimport {
  width: 200px;
  margin-bottom: 16px !important;
}

.content {
  padding: var(--page-padding);
}

.form > *:not(:last-child) {
  margin-bottom: 8px;
}

.form {
  text-align: right;
}

.ids {
  margin-top: 16px;
}

.onlyadmin {
  font-style: italic;
  color: grey;
  font-weight: normal;
}

.marginBottom {
  display: block;
  margin-bottom: 8px;
}
